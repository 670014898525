module.exports = {
    email: 'E-post',
    email_placeholder: 'exempel@exempel.se',
    email_invalid: 'E-posten är inte korrekt ifyllt!',
    password: 'Lösenord',
    confirm_password: 'Bekräfta lösenord',
    password_requirements: 'Minst 8 tecken, 1 siffra, 1 liten bokstav och en stor bokstav!',
    login: 'Logga in',
    logout: 'Logga ut',
    createaccount: 'Skapa konto gratis',
    forgotpassword: 'Glömt lösenordet?',
    roleincompany: 'Vilken roll har du i företaget?',
    roleincompany_placeholder: 'Exempelvis "marknadsförare"',
    companydetails: 'Företagsuppgifter',
    companyname: 'Företagsnamn',
    companyname_placeholder: 'Exempel AB',
    orgnumber: 'Organisations nr.',
    orgnumber_format: 'Måste skrivas "xxxxxx-xxxx"',
    orgnumber_invalid: 'Ogiltigt organisations nr.',
    personaldetails: 'Personuppgifter',
    firstname: 'Förnamn',
    lastname: 'Efternamn',
    firstlastname_required: 'För och efternamn får inte lämnas tomt!',
    phonenumber: 'Telefonnummer',
    companyaddress: 'Företagsadress',
    companyaddress_placeholder: 'Exempelvägen 32',
    postalcode: 'Postkod',
    city: 'Ort',
    fulladdress_required: 'Adress, postnummer och ort måste vara ifyllt!',
    alreadyhaveaccount: 'Har du redan ett konto?',
    updowngrade_afterregister: 'Det går alltid att uppgradera eller nedgradera efter registrering!',
    accountisnotverified_nocampaigns: 'Kontot är inte verifierat och därför kommer inte kampanjerna att visas för kunder!',
    account_must_verified: 'Konto måste vara verifierat',
    planselection: {
        pricemonthly: '{price}kr<small class="text-muted fw-light">/mån</small>',
        perstore_krperreceipt: '<small style="font-size: 12px; display: block" class="text-muted fw-light">per butik, +{receipt_price} kr per kvitto.</small>',
        offerdigitalreceipts: 'Erbjud digitala kvitton till befintliga och nya kunder*',
        activeads: '{ad_amount} aktiva annonser som når ut till befintliga och nya kunder i Bokaklipp',
        adtousers: 'Annonsera till samtliga användare i Bokaklipp',
        extraadfor: 'Möjlighet att köpa extra annons för {price}kr/månad',
    },
    saveprofile: 'Spara profil',
    changepassword: 'Ändra lösenord',
    passwordresetlinksent: 'En återställningslänk har skickats!',
}