module.exports = {
    title: 'Title',
    description: 'Description',
    totals: 'No. of bookings / Total available',
    total_value: 'Total value',
    discount: 'Discount',
    yourdiscount: 'Your discount',
    discount_required: 'You must enter a discount',
    smsText: 'SMS message',
    active: 'Active',
    activate: 'Activate',
    from: 'From',
    until: 'Until',
    options: 'Options',
    iaccept: 'I accept',
    nextstep: 'Start',
    termsofuse: 'Terms of Use',
    for: 'for',
    yes: 'Yes',
    yes_send: 'Yes, send',
    yes_delete: 'Yes, delete',
    no: 'No',
    choose: 'Choose',
    loading: 'Loading',
    processing: 'Processing',
    dont_leave_processing: 'Do not leave the page while it\'s processing',
    name: 'Name',
    address: 'Address',
    totalTargets: 'Total targets',
    totalinteractions: 'Total interactions',
    mostpopularad: 'Most popular ad',
    activeads: 'Active ads',
    addnewcampaign: 'Add new campaign',
    profile: 'Profile',
    articledatabase: 'Article database',
    campaigns: 'Campaigns',
    youhavenocampaigns: 'You have no campaigns',
    targetgroups: 'Target groups',
    storegroups: 'Store groups',
    verified: 'Verified',
    store: 'Store',
    nostore: 'No store',
    subscription: 'Subscription',
    create: 'Create',
    upload: 'Upload',
    createstoremanually: 'Create store manually',
    statistics: 'Statistics',
    users: 'Users',
    addreceiptsmanually: 'Add receipts manually',
    createnewcampaign: 'Create new campaign',
    editcampaign: 'Edit campaign',
    chooseimageforcampaign: 'Choose image for your campaign',
    image_required: 'Image is required',
    delete: 'Delete',
    cancel: 'Cancel',
    validate: 'Validate',
    comingsoon: 'Coming soon',
    campaigncode: 'Campaign code',
    hyperlink: 'Hyperlink',
    createcampaign: 'Create campaign',
    savecampaign: 'Save campaign',
    savechanges: 'Save changes',
    title_required: 'You must enter a title',
    description_required: 'You must enter a description',
    sms_required: 'Sms is required!',
    choosecampaignperiod: 'Choose time period for campaign',
    choosecollectionperiod: 'Choose collection period',
    choosebookingperiod: 'Choose booking period',
    date_required: 'Date is required',
    smslist: 'Enter your number',
    smslist_required: 'Field is required',    weekdays: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        short: {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
        }
    },
    sku: 'SKU',
    sku_placeholder: 'Enter variant SKU',
    variant: 'Variant',
    variant_placeholder: 'Enter variant name',
    unit: 'Unit',
    unit_placeholder: 'Enter unit',
    minPerCustomer: 'Min per Customer',
    minPerCustomer_placeholder: 'Enter min per customer',
    maxPerCustomer: 'Max per Customer',
    maxPerCustomer_placeholder: 'Enter max per customer',
    availableQuantity: 'Available Quantity',
    availableQuantity_placeholder: 'Enter total available quantity',
    regularPrice: 'Regular Price',
    regularPrice_placeholder: 'Enter price',
    discountedPrice: 'Discounted Price',
    discountedPrice_placeholder: 'Enter price',
    discountedPercentage: 'Discounted Percentage',
    discountedPercentage_placeholder: 'Enter %',
    note: 'Note',
    note_placeholder: 'Enter additional note for variant',
    addNewVariant: 'Add new variant',
    copyGenericSubscriptionLink: 'Press to copy generic subscription link',
    receiptsfetched: 'Receipts fetched',
    send_sms: 'Send SMS',
    sendcampaign_via_sms: 'Send campaign via SMS',
    sendcampaignto: 'Send campaign to',
    campaign_must_activate: 'Campaign must be active and today\'s date must be within its date range.',
    upload_csv_list: 'Add phone number',
    are_you_sure: 'Are you sure?',
    delete_selected: 'Delete selected',
    create_list : 'Create list',
    create_sms_list: 'Create new SMS list',
    phone_first_column: 'Make sure the phone numbers are in the first column of your CSV file.',
    phone_comma_seperated: 'Make sure multiple numbers are comma seperated',
    file_selected: 'File selected',
    to_replace_file: 'To replace this file, drag and drop another file or click here to browse.',
    drop_csv_browse: 'Drop CSV file here or click to browse',
    delete_following_lists: 'Do you want to delete the following lists?',
    action_irreversible: 'This action is irreversible.',
}