module.exports = {
    en: {
        general: require('./en/general'),
        auth: require('./en/auth'),
        validation: require('./en/validation'),
    },
    se: {
        general: require('./se/general'),
        auth:require('./se/auth'),
        validation: require('./se/validation'),
    }
};