module.exports = {
    title: 'Titel',
    description: 'Beskrivning',
    totals: 'Antal bokningar / Totalt antal tillgängliga för bokning',
    total_value: 'Bokningsvärde',
    discount: 'Rabatt',
    yourdiscount: 'Din rabatt',
    discount_required: 'Du måste fylla i rabatt!',
    smsText: 'SMS-text',
    active: 'Aktiv',
    activate: 'Aktivera',
    from: 'Fr.o.m.',
    until: 'T.o.m.',
    options: 'Alternativ',
    iaccept: 'Jag godkänner',
    nextstep: 'Starta',
    termsofuse: 'Användarvillkoren',
    for: 'för',
    yes: 'Ja',
    yes_send: 'Ja, skicka',
    yes_delete: 'Ja, ta bort',
    no: 'Nej',
    choose: 'Välj',
    loading: 'Laddar',
    processing: 'Bearbetar',
    dont_leave_processing: 'Lämna inte sidan medan den bearbetas',
    name: 'Namn',
    address: 'Adress',
    totalTargets: 'Antal kunder',
    totalinteractions: 'Skickade SMS',
    mostpopularad: 'Klick på erbjudande',
    activeads: 'Aktiverade erbjudanden',
    addnewcampaign: 'Lägg till nytt erbjudande',
    profile: 'Profil',
    articledatabase: 'Artikeldatabas',
    campaigns: 'Kampanjer',
    youhavenocampaigns: 'Du har inga kampanjer',
    targetgroups: 'Målgrupper',
    storegroups: 'Butiksgrupper',
    verified: 'Verifierad',
    store: 'Butik',
    nostore: 'Ingen butik',
    subscription: 'Prenumeration',
    create: 'Skapa',
    upload: 'Ladda upp',
    createstoremanually: 'Skapa butik manuellt',
    statistics: 'Statistik',
    users: 'Användare',
    addreceiptsmanually: 'Läs in kvitton manuellt',
    createnewcampaign: 'Skapa en ny kampanj',
    editcampaign: 'Redigera kampanj',
    chooseimageforcampaign: 'Välj bild till din kampanj',
    image_required: 'Bild saknas',
    delete: 'Ta bort',
    cancel: 'Avbryt',
    validate: 'Validera',
    comingsoon: 'Coming soon',
    campaigncode: 'Kampanjkod',
    hyperlink: 'Hyperlänk',
    createcampaign: 'Skapa kampanj',
    savecampaign: 'Spara kampanj',
    savechanges: 'Spara ändringar',
    title_required: 'Du måste fylla i rubriken!',
    description_required: 'Beskrivningen måste fyllas i!',
    sms_required: 'Sms is required!',
    choosecampaignperiod: 'Välj tidsperiod för kampanjen',
    choosecollectionperiod: 'Choose collection period',
    choosebookingperiod: 'Choose booking period',
    date_required: 'Datum saknas',
    smslist: 'skriv in nummer här',
    smslist_required: 'Field is required',
    weekdays: {
        monday: 'Måndag',
        tuesday: 'Tisdag',
        wednesday: 'Onsdag',
        thursday: 'Torsdag',
        friday: 'Fredag',
        saturday: 'Lördag',
        sunday: 'Söndag',
        short: {
            monday: 'Mån',
            tuesday: 'Tis',
            wednesday: 'Ons',
            thursday: 'Tors',
            friday: 'Fre',
            saturday: 'Lör',
            sunday: 'Sön',
        }
    },
    sku: 'SKU',
    sku_placeholder: 'SKU variant',
    variant: 'Variant',
    variant_placeholder: 'Variant namn',
    unit: 'Enhet',
    unit_placeholder: 'Enhet',
    minPerCustomer: 'Min bokningar per kund',
    minPerCustomer_placeholder: 'Min bokningar per kund',
    maxPerCustomer: 'Max bokningar per kund',
    maxPerCustomer_placeholder: 'Max bokningar per kund',
    availableQuantity: 'Antal produkter tillgängliga för bokning',
    availableQuantity_placeholder: 'Antal produkter tillgängliga för bokning',
    regularPrice: 'Ord. pris',
    regularPrice_placeholder: 'Ord. pris',
    discountedPrice: 'Rabatterat pris',
    discountedPrice_placeholder: 'Rabatterat pris',
    discountedPercentage: 'Procentrabatt',
    discountedPercentage_placeholder: '%',
    note: 'Ytterligare text',
    note_placeholder: 'Ytterligare text',
    addNewVariant: 'Lägg till variant',
    copyGenericSubscriptionLink: 'Tryck för att kopiera länk till Bokaklipp',
    receiptsfetched: 'Kvitton har hämtats',
    send_sms: 'Skicka SMS',
    sendcampaign_via_sms: 'Skicka erbjudande via SMS',
    sendcampaignto: 'Skicka erbjudande till',
    campaign_must_activate: 'Kampanjen måste vara aktiv och dagens datum måste vara inom dens datum.',
    upload_csv_list: 'lägg till telefonnummer',
    are_you_sure: 'Är du säker?',
    delete_selected: 'Ta bort markerade',
    create_list : 'Skapa lista',
    create_sms_list: 'Skapa ny SMS-lista',
    phone_first_column: 'Kontrollera att telefonnummer står i den första kolumnen i din CSV-fil.',
    phone_comma_seperated: 'se till att separera nummer med ett commatecken ifall du lägger in flera nummer samtidigt',
    file_selected: 'Vald fil',
    to_replace_file: 'För att ersätta den här filen, dra och släpp en annan fil eller klicka här för att bläddra.',
    drop_csv_browse: 'Släpp din telefonlista här eller klicka för att ladda upp från din dator',
    delete_following_lists: 'Vill du ta bort följande listor?',
    action_irreversible: 'Denna åtgärd går inte att ångra.',
}