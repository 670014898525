import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { auth } from '@/firebase'
import store from '@/store';
import App from './App.vue'
import router from './router'
import Vue3Toastify from 'vue3-toastify';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'vue3-toastify/dist/index.css';
import '@/assets/css/global.css'

const i18n = createI18n({
    locale: localStorage.getItem('locale') ?? 'se',
    fallbackLocale: 'en',
    messages: require('./lang')
});

const unsubscribe = auth
    .onAuthStateChanged(() => {
        createApp(App, {
            created() {
            },
        })
            .use(i18n)
            .use(router)
            .use(store)
            .use(Vue3Toastify, {
                autoClose: 2000,
            })
            .mount('#app')
        unsubscribe();
    });
