
import { auth } from '@/firebase'
import { createRouter, createWebHistory } from 'vue-router'

const routerOptions = [
  {
    path: '/',
    name: 'Home',
    component: 'Home',
    meta: {
      requiresAuth: true
    }
  },
  {
  path: '/auth',
  name: 'Auth',
  component: 'Auth',
  meta: {
    guest: true
  }
},

{
  path: '/target',
  name: 'Target',
  component: 'Target',
  meta: {
    requiresAuth: true
  }
},
{
  path: '/articleDatabase',
  name: 'ArticleDatabase',
  component: 'ArticleDatabase',
  meta: {
    requiresAuth: true
  }
},
{
  path: '/dashboard',
  name: 'Dashboard',
  component: 'Dashboard',
  meta: {
    requiresAuth: true
  }
},
{
  path: '/profile',
  name: 'Profile',
  component: 'Profile',
  meta: {
    requiresAuth: true
  }
},
{
  path: '/storeTarget',
  name: 'StoreTarget',
  component: 'StoreTarget',
  meta: {
    requiresAuth: true
  }
},
{
  path: '/receipt/:phoneNumber-:receiptId',
  name: 'ReceiptView',
  component: 'ReceiptView',
  meta: {
    guest: true
  }
},
{
  path: '/offer/:phoneNumber-:offerId',
  name: 'OfferView',
  component: 'OfferView',
  meta: {
    guest: true
  }
},
{
  path: '/offer/general/:campaignId',
  name: 'GeneralOfferView',
  component: 'OfferView',
  meta: {
    guest: true
  }
},
{
  path: '/subscribe/:merchantId',
  name: 'MerchantSubscribeView',
  component: 'MerchantSubscribeView',
  meta: {
    guest: true
  }
},
{ path: '/:pathMatch(.*)*', name: 'NotFound', component: '404' },
];


const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(/* webpackChunkName: "{{route.component}}" */ `../views/${route.component}.vue`)
  };
});


let router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

function authUser() {
  return new Promise((resolve) => {
    auth.onAuthStateChanged((userAuth) => {
      
      if (!userAuth) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
}


router.beforeEach(async (to, from, next) => {
  const userAuth = await authUser();
  if (userAuth) {
    await auth.currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (claims.admin || claims.guest) {
          if (claims.guest) {
            if (to.path === '/dashboard') {
              return next({
                path: '/'
              })
            }
            if (to.path === '/target') {
              return next({
                path: '/',
              });
            }
            if (to.path === '/statistics') {
              return next({
                path: '/',
              });
            }
            if (to.path === '/storeTarget') {
              return next({
                path: '/',
              });
            }
          }
          if (claims.admin) {
            // if(to.path === '/') return next({path: '/dashboard'})
          }
          if (to.path === '/auth') {
            return next('/')
          }
        } else {
          if(to.path !== '/') {
            return next('/')
          }
        }
      }).catch((error) => {
        console.log('Router error: ', error);
      })
  }
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;
  if (requiresAuth && !isAuthenticated) {
    next('/auth');
  } else {
    next();
  }
});

export default router
