import {
  auth,
  createMerchantUser,
  getMerchantUserProfile,
  getMerchantUserRole,
} from '@/firebase'
import router from '../../router/index'

const state = {
  userProfile: {},
  userRole: {},
}

export const getters = {
  getUserProfile(state) {
    return state.userProfile
  },
  isAuthenticated(state) {
    return !!state.userProfile
  },
  getUserRole() {
    return state.userRole
  },
}

export const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val
  },
  setUserRole(state, val) {
    state.userRole = val
  },
}

export const actions = {
  async login({ commit, dispatch }, payload) {
    commit('setLoading', true)
    await auth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((firebaseData) => {
        dispatch('fetchUserProfile', firebaseData.user)
        commit('setLoading', false)
        commit('setError', null)
        router.push('/')
      })
      .catch((error) => {
        commit('setLoading', false)
        commit('setError', { login: error })
      })
  },
  async logout({ commit }) {
    await auth.signOut()
    commit('setUserProfile', {})
    router.currentRoute.path !== '/auth' && router.push('/auth')
  },
  async signup({ commit }, payload) {
    commit('setLoading', true)
    await auth
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        // TODO: prints email verification in console in dev mode
        // auth.currentUser.sendEmailVerification()
        delete payload.password
        createMerchantUser(payload).then(() => {
          commit('setLoading', false)
          commit('setInformation', {
            signUp: {
              code: 'Success',
              message: `User created!, use your new credentials`,
            },
          })
          commit('setError', null)
          router.push('/')
        })
      })
      .catch((error) => {
        commit('setLoading', false)
        commit('setInformation', null)
        commit('setError', { signUp: error })
      })
  },
  async fetchUserProfile({ commit }, user) {
    commit('setLoading', true)
    try {
      const userInfo = await getMerchantUserProfile(user.uid)

      commit('setUserProfile', userInfo?.enable ? userInfo : {})
      if (userInfo) {
        // !userInfo.enable && dispatch('logout');
        if (userInfo.enable) {
          router.push('/')
        }
        commit('setLoading', false)
        commit('setError', null)
      }
    } catch (error) {
      commit('setError', error)
      commit('setLoading', false)
    }
  },
  async fetchUserRole({ commit }, user) {
    commit('setLoading', true)
    try {
      const userInfo = await getMerchantUserRole(user.uid)

      commit('setUserRole', userInfo?.role ? userInfo.role : {})
      if (userInfo) {
        // Kolla role på användare
        commit('setLoading', false)
        commit('setError', null)
      }
    } catch (error) {
      commit('setError', error)
      commit('setLoading', false)
    }
  },
  async resetPassword({ commit }, payload) {
    commit('setLoading', true)
    await auth
      .sendPasswordResetEmail(payload.email)
      .then(() => {
        commit('setLoading', false)
        commit('setInformation', {
          resetPassword: {
            code: 'Success',
            message: 'Återställningslänken har skickats till din e-post!',
          },
        })
        commit('setError', null)
      })
      .catch((error) => {
        commit('setLoading', false)
        commit('setInformation', null)
        commit('setError', { resetPassword: error })
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
