module.exports = {
    email: 'E-mail',
    email_placeholder: 'example@example.com',
    email_invalid: 'E-mail is not valid!',
    password: 'Password',
    confirm_password: 'Confirm password',
    password_requirements: 'At least 8 characters, 1 number, 1 lower and uppercase character!',
    login: 'Log in',
    logout: 'Log out',
    createaccount: 'Create account for free',
    forgotpassword: 'Forgot password?',
    roleincompany: 'What role do you have in your company?',
    roleincompany_placeholder: 'For example "marketer"',
    companydetails: 'Company details',
    companyname: 'Company name',
    companyname_placeholder: 'Example AB',
    orgnumber: 'Organization nr.',
    orgnumber_format: 'Format is "xxxxxx-xxxx"',
    orgnumber_invalid: 'Invalid organisation number',
    personaldetails: 'Personal details',
    firstname: 'First name',
    lastname: 'Last name',
    firstlastname_required: 'First and lastname is required!',
    phonenumber: 'Phone number',
    companyaddress: 'Company address',
    companyaddress_placeholder: 'Example Street 32',
    postalcode: 'Postal code',
    city: 'City',
    fulladdress_required: 'Address, postal code and city is required!',
    alreadyhaveaccount: 'Do you already have an account?',
    updowngrade_afterregister: 'You can always upgrade or downgrade after registration!',
    accountisnotverified_nocampaigns: 'Account is not verified and campaigns will therefore not show for customers!',
    account_must_verified: 'Account must be verified',
    planselection: {
        pricemonthly: '{price}kr<small class="text-muted fw-light">/mon</small>', 
        perstore_krperreceipt: '<small style="font-size: 12px; display: block" class="text-muted fw-light">per store, +{receipt_price} kr per receipt.</small>',
        offerdigitalreceipts: 'Offer digital receipts to existing and new customers*',
        activeads: '{ad_amount} active ads that reach existing and new customers in Bokaklipp',
        adtousers: 'Advertise to set users in Bokaklipp',
        extraadfor: 'Option to buy extra ad for {price}kr/month',
    },
    saveprofile: 'Save profile',
    changepassword: 'Change password',
    passwordresetlinksent: 'A password reset link has been sent!',
}