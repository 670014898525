import { createStore, createLogger} from 'vuex'

import authentication from './modules/authentication.js';

const debug = process.env.NODE_ENV !== 'production'

// Create a new store instance.
const store = createStore({
    state: {
        error: null,
        loading: false,
        information: null
      },
      getters: {
        getError(state) {
          return state.error;
        },
        getInformation(state) {
          return state.information;
        },
        getLoading(state) {
          return state.loading;
        },
      },
      mutations: {
        setInformation(state, payload) {
          state.information = payload
        },
        setError(state, payload) {
          state.error = payload
        },
        setLoading(state, payload) {
          state.loading = payload
        }
      },
      actions: {},
      modules: {
        authentication
      },
      strict: debug,
        plugins: debug ? [createLogger()] : []
})

export default store;
