//import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/functions'
import { ref, onUnmounted } from 'vue'
require('dotenv').config()

const config = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

const firebaseApp = firebase.initializeApp(config)
const storage = firebaseApp.storage()

const auth = firebaseApp.auth()
const db = firebaseApp.firestore()
const fireFunctions = firebaseApp.functions(process.env.VUE_APP_REGION)

// ADD THESE LINES
if (location.hostname === 'localhost') {
  console.log('localhost detected!')
  auth.useEmulator('http://localhost:9099')
  db.useEmulator('localhost', 3000)
  storage.useEmulator('localhost', 9199)
  fireFunctions.useEmulator('localhost', 5001)
}

// Set db Collections
// const campaignCollection = db.collection('campaign')
const targetGroupCollection = db.collection('targetGroup')
const storesCollection = db.collection('stores')
const storeTargetCollection = db.collection('storeTargets')
const usersCollection = db.collection('merchantUsers')
const usersRole = db.collection('merchantRoles')
const phoneNumberUsers = db.collection('phoneNumberUsers')
const campaignCollection = db.collection('campaign')

export const getReceipt = async (phoneNumber, receiptId) => {
  const receiptRef = phoneNumberUsers
    .doc(phoneNumber)
    .collection('receipts')
    .doc(receiptId)
  const receiptDocument = await receiptRef.get()
  return receiptDocument.exists ? receiptDocument.data() : null
}

export const getOffer = async (phoneNumber, offerId) => {
  const offerRef = phoneNumberUsers
    .doc(phoneNumber)
    .collection('campaigns')
    .doc(offerId)
  const offerDocument = await offerRef.get()
  return offerDocument.exists ? offerDocument.data() : null
}

export const getMerchantData = async (merchantId) => {
  const merchantRef = await usersCollection
    .doc(merchantId).get()
  return merchantRef.exists ? merchantRef.data() : null
}


export const optOutFromOffer = async (merchantId, phoneNumber, offerId) => {
  const merchantOfferTargetPath = db
    .collection('merchantUsers')
    .doc(merchantId)
    .collection('targets')
    .doc(phoneNumber)

  await merchantOfferTargetPath.delete()

  const offerRef = phoneNumberUsers
    .doc(phoneNumber)
    .collection('campaigns')
    .doc(offerId)
  await offerRef.update({
    hasOptedOut: true,
  })
}

export const optOutFromTargetList = async (merchantId, phoneNumber) => {
  const merchantTargetPath = db
    .collection('merchantUsers')
    .doc(merchantId)
    .collection('targets')
    .doc(phoneNumber)

  await merchantTargetPath.delete()

  const merchantTargetListPath = db
    .collection('merchantUsers')
    .doc(merchantId)
    .collection('targetLists')
    .doc('general')

  const targetListData = await merchantTargetListPath.get()
  const targetListDoc = targetListData.data().phoneNumbers ?? []
  const filteredNumbers = targetListDoc.filter(e => e !== phoneNumber);
  merchantTargetListPath.update({ phoneNumbers: filteredNumbers });
}

// All the campagin functions that are used through the application.

// Done
export const createCampaign = (campaign) => {
  console.log('we came here')
  var createNewCampaign = fireFunctions.httpsCallable('createNewCampaign')
  const timeStamp = Math.floor(Date.now() / 1000)
  const imageFile = campaign.photoData
  const storageRef = storage.ref()

  var uploadTask = storageRef
    .child('campaigns/' + timeStamp + '-' + 'campaign')
    .put(imageFile)

  // Listen for state changes, errors, and completion of the upload.
  return uploadTask
    .then(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
        }
      }
    )
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break
        case 'storage/canceled':
          // User canceled the upload
          break

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break
      }
    })
    .then(() => {
      const storageRef = uploadTask.snapshot.ref.fullPath
      // Upload completed successfully, now we can get the download URL
      return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL)
        campaign.photoData = downloadURL
        campaign.photoRef = storageRef
        return createNewCampaign(campaign)
          .then(function(result) {
            return console.log(result)
          })
          .catch(function(error) {
            return console.log(error)
          })
      })
    })
}

export const createSMSTargetList = async (list) => {
  let createSMSTargetList = fireFunctions.httpsCallable('createSMSTargetList')
  const result = await createSMSTargetList(list)
  return result
}

export const getSMSTargetLists = async (user) => {
  try {
    const targetsCollection = await usersCollection
      .doc(user)
      .collection('targets')
      .get()

    let merchantTargets = {
      name: 'Samtliga kunder',
      phoneNumbers: [],
    }

    targetsCollection.docs.forEach((doc) => {
      merchantTargets.phoneNumbers.push(doc.id)
    })

    const targetListsCollection = await usersCollection
      .doc(user)
      .collection('targetLists')
      .get()

    let targetLists = targetListsCollection.docs.map((doc) => ({
      name: doc.id,
      phoneNumbers: doc.data().phoneNumbers,
    }))

    targetLists.unshift(merchantTargets)

    if (targetLists.length == 0)
      return { message: 'Could not find target lists!', error: true }
    return targetLists
  } catch (err) {
    console.error(err)
    return { message: 'Could not fetch target lists!', error: true }
  }
}

export const sendCampaignSMS = async (data) => {
  let sendCampaignSMS = fireFunctions.httpsCallable('sendCampaignSMS')
  const result = await sendCampaignSMS(data)
  return result
}

export const deleteSMSTargetLists = async (data) => {
  let deleteSMSTargetLists = fireFunctions.httpsCallable('deleteSMSTargetLists')
  const result = await deleteSMSTargetLists(data)
  return result
}

export const createCampaignDos = async (campaign) => {
  var createNewCampaign = fireFunctions.httpsCallable('createNewCampaign')
  const timeStamp = Math.floor(Date.now() / 1000)
  const imageFile = campaign.photoData
  const storageRef = storage.ref()

  async function uploadTaskPromise() {
    return new Promise(function(resolve, reject) {
      const uploadTask = storageRef
        .child('campaigns/' + timeStamp + '-' + 'campaign')
        .put(imageFile)

      uploadTask.on(
        'state_changed',
        function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
        },
        function error(err) {
          console.log('error', err)
          reject()
        },
        function complete() {
          const uploadedRef = uploadTask.snapshot.ref.fullPath
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve({ downloadURL, uploadedRef })
          })
        }
      )
    })
  }

  try {
    const data = await uploadTaskPromise()
    campaign.photoData = data.downloadURL
    campaign.photoRef = data.uploadedRef

    console.log('Will create new campaign', JSON.stringify(campaign))

    const result = await createNewCampaign(campaign)
    return result
  } catch (error) {
    console.error(error)
    return console.log('Could not create a new campaign: ', error)
  }
}

// Done
export const merchantUserInfo = async () => {
  // const merchant = ref([])
  // var getMerchantUserInfo = fireFunctions.httpsCallable("getMerchantUserInfo");
  // const close = usersCollection.onSnapshot(() => {
  //   getMerchantUserInfo()
  //     .then(function (result) {
  //       merchant.value = result.data;
  //     })
  //     .catch(function (error) {
  //       console.log("Något gick fel!", error)
  //     });
  // })

  // onUnmounted(close)
  // return merchant
  try {
    var getMerchantUserInfo = fireFunctions.httpsCallable('getMerchantUserInfo')
    const result = await getMerchantUserInfo()
    return result.data
  } catch (error) {
    console.log('Could not fetch merchantInformation: ', error)
  }
}

// Done
export const getCampaign = async (id, user) => {
  // return usersCollection.doc(user)
  // .collection('campaigns').doc(id).get().then((doc) => {
  //   if (doc.exists) {
  //       return doc.data()
  //   } else {
  //       console.log("No merchant Profile!");
  //   }
  // }).catch((error) => {
  //     console.log("Error getting Merchant Profile:", error);
  // });
  try {
    const campaign = await usersCollection
      .doc(user)
      .collection('campaigns')
      .doc(id)
      .get()
    if (!campaign.exists)
      return { message: 'Could not get campaign data!', error: true }
    console.log(campaign.data())
    return campaign.data()
  } catch (err) {
    return { message: 'Could not get campaign!', error: true }
  }
}

export const getCampaignById = async (id) => {
  try {
    const campaign = await campaignCollection
      .doc(id)
      .get()
    if (!campaign.exists)
      return { message: 'Could not get campaign data!', error: true }
    return campaign.data()
  } catch (err) {
    return { message: 'Could not get campaign!', error: true }
  }
}

export const getOrdersByCampaignId = async (merchantId, campaignId) => {
  try {
    const campaign = (await usersCollection.doc(merchantId).collection('campaigns').doc(campaignId).get())
    if (!campaign.exists)
      return { message: 'Could not get campaign data!', error: true }

    const campaignData = campaign.data()

    const orders = campaignData.orders;

    const formattedOrders = {};
    Object.entries(orders).forEach(([phoneNumber, userOrders]) => {
      userOrders.forEach((order) => {
        const { sku, quantity } = order;
        if (!formattedOrders[sku]) {
          formattedOrders[sku] = { total: 0, orders: [] };
        }
        formattedOrders[sku].total += quantity;
        formattedOrders[sku].orders.push(phoneNumber);
      });
    });

    console.log(formattedOrders);
    return formattedOrders;
  } catch(err) {
    return { message: 'Could not get orders!', error: true, msg: err.message }
  }
}

export const getOrdersSortedByPhone = async (merchantId, campaignId) => {
  try {
    const campaign = (await usersCollection.doc(merchantId).collection('campaigns').doc(campaignId).get())
    if (!campaign.exists)
      return { message: 'Could not get campaign data!', error: true }

    const campaignData = campaign.data()

    const orders = campaignData.orders;

    const formattedOrders = [];
    Object.entries(orders).forEach(([phoneNumber, userOrders]) => {
      formattedOrders.push({
        number: phoneNumber,
        orders: userOrders,
      })
    });
    return formattedOrders;
  } catch(err) {
    return { message: 'Could not get orders!', error: true, msg: err.message  }
  }
}

// Done
export const updateCampaign = async (id, campaign) => {
  var editCampaign = fireFunctions.httpsCallable('editCampaign')

  const data = { id: id, campaign: campaign }
  const oldPhotoData = data.campaign.oldPhotoData
  delete data.campaign.oldPhotoData

  const timeStamp = Math.floor(Date.now() / 1000)
  const imageFile = campaign.photoData
  const storageRef = storage.ref()

  async function uploadTaskPromise() {
    return new Promise(function(resolve, reject) {
      const uploadTask = storageRef
        .child('campaigns/' + timeStamp + '-' + 'campaign')
        .put(imageFile)

      uploadTask.on(
        'state_changed',
        function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
        },
        function error(err) {
          console.log('error', err)
          reject()
        },
        function complete() {
          const uploadedRef = uploadTask.snapshot.ref.fullPath
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve({ downloadURL, uploadedRef })
          })
        }
      )
    })
  }

  if (oldPhotoData === campaign.photoData) {
    try {
      const result = await editCampaign(data)
      if (result.data.error) throw result.data

      return result.data
    } catch (err) {
      return err
    }
  } else {
    try {
      const uploadData = await uploadTaskPromise()
      campaign.photoData = uploadData.downloadURL
      campaign.photoRef = uploadData.uploadedRef

      const result = await editCampaign(data)
      if (result.data.error) throw result.data

      return result.data
    } catch (err) {
      return err
    }
  }
}

// Done
export const deleteCampaign = async (id) => {
  const user = auth.currentUser
  try {
    if (!user) throw 'No user found!'
    console.log(id, user)
    await usersCollection
      .doc(user.uid)
      .collection('campaigns')
      .doc(id)
      .delete()
    return { message: 'Successfully deleted campaign!', error: false }
  } catch (err) {
    return { message: 'Could not delete campaign!', error: true }
  }
}

// Done
export const useLoadCampaigns = async () => {
  const user = auth.currentUser
  try {
    if (!user) throw { message: 'No user found!', error: true }

    const userCampaigns = await usersCollection
      .doc(user.uid)
      .collection('campaigns')
      .orderBy('created', 'desc')
      .get()
    if (!userCampaigns)
      throw { message: 'Could not get campaigns!', error: true }
    const campaigns = userCampaigns.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    return campaigns
  } catch (err) {
    return err
  }
}

// Done
export const UpdateActiveCampaign = async (id, campaign) => {
  const user = auth.currentUser
  console.log(campaign.active)
  try {
    console.log('Pressed')
    const campaignRef = usersCollection
      .doc(user.uid)
      .collection('campaigns')
      .doc(id)
    await campaignRef.update(campaign)

    const snapshot = await campaignRef.get()
    const customerOffers = snapshot.data().customerOffers
    console.log('customerOffers', customerOffers)

    const updateCustomersOffers = customerOffers.map((offer) => {
      return offer.update(campaign)
    })

    await Promise.all(updateCustomersOffers)

    return { message: 'Toggled campaign successfully!', error: false }
  } catch (err) {
    console.log(err)
    return { message: 'Could not toggle campaign!', error: true }
  }
}

// All the Targetgroup functions used through the application.

// Done
export const createTarget = (target) => {
  // target['created'] = firebase.firestore.FieldValue.serverTimestamp();
  // return targetGroupCollection.add(target)
  var createNewTarget = fireFunctions.httpsCallable('createNewTarget')
  return createNewTarget(target)
    .then(function(result) {
      return result
    })
    .catch(function(error) {
      console.log(error)
    })
}

// Create storeTarget
export const createStoreTarget = async (target) => {
  // target['created'] = firebase.firestore.FieldValue.serverTimestamp();
  // return targetGroupCollection.add(target)

  // var createStoreTarget = fireFunctions.httpsCallable("createStoreTarget");
  // return createStoreTarget(target)
  //   .then(function (result) {
  //     return result
  //   })
  //   .catch(function (error) {
  //     console.log(error)
  //   });
  try {
    const time = firebase.firestore.FieldValue.serverTimestamp()
    await storeTargetCollection.add({
      created: time,
      ...target,
    })

    return { message: 'Created storeTarget successfully!', error: false }
  } catch (err) {
    console.log(err)
    return { message: 'Could not create storeTarget!', error: true }
  }
}

// Done
export const getTarget = (id) => {
  // const target = await targetGroupCollection.doc(id).get()
  // return target.exists ? target.data() : null
  var fetchTarget = fireFunctions.httpsCallable('fetchTarget')
  return fetchTarget(id)
    .then(function(result) {
      return result
    })
    .catch(function(error) {
      console.log(error)
    })
}

// Get StoreTarget by id
export const getStoreTarget = async (id) => {
  try {
    const result = await storeTargetCollection.doc(id).get()
    console.log(result)
    return result.data()
  } catch (error) {
    console.log(error)
    return error
  }
}

// Done
export const updateTarget = (id, target) => {
  // return targetGroupCollection.doc(id).update(target)
  const data = { id: id, target: target }

  var editTarget = fireFunctions.httpsCallable('editTarget')
  editTarget(data)
    .then(function() {})
    .catch(function(error) {
      console.log(error)
    })
}

// Done
export const updateStoreTarget = (id, target) => {
  // return targetGroupCollection.doc(id).update(target)
  const data = { id: id, target: target }
  console.log(data)
  var updateStoreTarget = fireFunctions.httpsCallable('updateStoreTarget')
  updateStoreTarget(data)
    .then(function() {})
    .catch(function(error) {
      console.log(error)
    })
}

// Done
export const deleteTarget = (id) => {
  // return targetGroupCollection.doc(id).delete()
  var delTarget = fireFunctions.httpsCallable('delTarget')
  delTarget(id)
    .then(function(result) {
      return result
    })
    .catch(function(error) {
      console.log('Något gick fel!', error)
    })
}

// Delete StoreTarget
export const deleteStoreTarget = async (id) => {
  try {
    await storeTargetCollection.doc(id).delete()
    return { message: 'StoreTarget successfully deleted!', error: false }
  } catch (error) {
    return { message: 'StoreTarget could not be deleted!', error: true }
  }
}

// Done
export const useLoadTarget = () => {
  const target = ref([])
  var loadTarget = fireFunctions.httpsCallable('loadTarget')
  const close = targetGroupCollection.onSnapshot(() => {
    loadTarget()
      .then(function(result) {
        target.value = result.data
      })
      .catch(function(error) {
        console.log('Något gick fel!', error)
      })
  })

  onUnmounted(close)
  return target
}

// Retrive StoreTargets list
export const useLoadStoreTarget = async () => {
  try {
    storeTargetCollection.orderBy('created', 'desc').onSnapshot((documents) => {
      const sortedTargets = []
      documents.forEach((doc) => {
        sortedTargets.push({
          id: doc.id,
          ...doc.data(),
        })
      })
    })
  } catch (error) {
    return console.log('Could not fetch storeTargets', error)
  }
}

// Functions to get the stores documents.

// Done
export const useLoadGroup = () => {
  const group = ref([])
  var loadGroup = fireFunctions.httpsCallable('loadGroup')
  const close = targetGroupCollection.onSnapshot(() => {
    loadGroup()
      .then(function(result) {
        group.value = result.data
      })
      .catch(function(error) {
        console.log('Något gick fel!', error)
      })
  })

  onUnmounted(close)
  return group
}

// Is not used right now
// done
export const getGroup = async (id) => {
  // const group = await storesCollection.doc(id).get()
  // return group.exists ? group.data() : null
  var fetchGroup = fireFunctions.httpsCallable('fetchGroup')
  return fetchGroup(id)
    .then(function(result) {
      return result
    })
    .catch(function(error) {
      console.log(error)
    })
}

// Functions for authentication

// export const verifyAuth = async () => {
//   const xy = {};
//   await auth.onAuthStateChanged(async userAuth => {
//     if (userAuth) {
//       xy.gs = await auth.currentUser.getIdTokenResult()
//         .then(function ({
//           claims
//         }) {
//           console.log("xxx ", claims)
//           return claims
//         })
//     } else {
//       console.log("Inte inloggad")
//     }
//   });
//   console.log("asdas", xy)
//   return xy
// }

// Check meta on user
export const metaCheck = () => {
  return auth.onAuthStateChanged((userAuth) => {
    if (userAuth) {
      auth.currentUser.getIdTokenResult().then((tokenResult) => {
        return tokenResult.claims
      })
    }
  })
}

// Login with email and password
export const loggaIn = (email, password) => {
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((err) => {
      console.log('Något gick fel vid inloggning: ', err)
    })
}

// Register with email and password
export const register = (email, password) => {
  auth.createUserWithEmailAndPassword(email, password)
}

//Logout with email and password!
export const logOut = () => {
  firebaseApp
    .auth()
    .signOut()
    .catch((err) => {
      console.log('Något gick fel vid utloggning: ', err)
    })
}

// Firebase storage

export const uploadArticleData = (file) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      const suffix = String(file.name)
      const dotSuffix = suffix.substring(suffix.indexOf('.') + 1)
      storage
        .ref('stores/' + user.uid)
        .list()
        .then((res) => {
          if (file) {
            if (res.items.length > 0) {
              storage
                .ref('stores/' + user.uid + '/article.' + dotSuffix)
                .delete()
                .then(() => {})
                .catch((error) => {
                  console.log(error)
                })
            }
            storage
              .ref('stores')
              .child(user.uid + '/article.' + dotSuffix)
              .put(file)
              .then(() => {})
              .catch((error) => {
                console.log(error)
              })
          } else {
            console.log('Du måste välja en fil')
          }
        })
        .catch((error) => {
          console.log('Något gick fel! ', error)
        })
    } else {
      console.log('Du måste vara inloggad!')
    }
  })
}

export const existsArticleData = () => {
  const amount = ref()
  auth.onAuthStateChanged((user) => {
    if (user) {
      storage
        .ref('stores/' + user.uid)
        .list()
        .then((res) => {
          amount.value = res.items.length
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      console.log('Du måste vara inloggad för att se detta!')
    }
  })
  return amount
}

export const removeArticleData = () => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      storage
        .ref('stores/' + user.uid)
        .list()
        .then((res) => {
          if (res.items.length > 0) {
            storage
              .ref('stores/' + user.uid + '/' + res.items[0].name)
              .delete()
              .then(() => {})
              .catch((error) => {
                console.log(error)
              })
          } else {
            console.log('Ingen fil hittades!')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      console.log('Du måste vara inloggad för att göra detta!')
    }
  })
}

export const getArticleData = () => {
  const blob = ref({})
  auth.onAuthStateChanged((user) => {
    if (user) {
      storage
        .ref('stores/' + user.uid + '/' + 'article.xlsx')
        .getDownloadURL()
        .then((url) => {
          // This can be downloaded directly:
          var xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = () => {
            blob.value = xhr.response
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch((error) => {
          // Handle any errors
          console.log('file not found: ', error)
        })
    } else {
      console.log('Du måste vara inloggad för att göra detta!')
    }
  })
  return blob
}

// Admin dashboard change enable and store via backend verification

// Done
export const updateUserInfo = async (data) => {
  var updateUser = fireFunctions.httpsCallable('setMerchantUserInfo')
  try {
    const result = await updateUser(data)
    if (result.data.error) throw result.data

    return result.data
  } catch (err) {
    return err
  }
}

export const getMerchantUserProfile = (data) => {
  const profileRef = usersCollection.doc(data)
  return profileRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data()
        // Uncomment below to allow all users as verified
        // return {...doc.data(), enable: true}
      } else {
        console.log('No merchant Profile!')
      }
    })
    .catch((error) => {
      console.log('Error getting Merchant Profile:', error)
    })
}

export const getMerchantUserRole = (data) => {
  var getMerchantUserRole = fireFunctions.httpsCallable('getMerchantUserRole')
  return getMerchantUserRole(data)
    .then(function(result) {
      return result.data
    })
    .catch(function() {
      console.log('Något gick fel med att hämta användaruppgifter!')
    })
}

export const createMerchantUser = (payload) => {
  var createMerchantUser = fireFunctions.httpsCallable('createMerchantUser')
  return createMerchantUser(payload)
    .then(() => {
      return console.log('User created')
    })
    .catch((error) => {
      console.log('Något gick fel med att registrera ett konto!')
      console.log(error)
    })
}

// Create store from the dashboard
export const createStore = async (storeName) => {
  var createNewStore = fireFunctions.httpsCallable('createNewStore')
  try {
    if (storeName === '') return console.log('No storeName')
    const result = await createNewStore(storeName)
    return result
  } catch (error) {
    console.log('Could not create store: ', error)
  }
}

export const editMerchantProfile = (profile) => {
  var editMerchantProfile = fireFunctions.httpsCallable('editMerchantProfile')
  const data = { profile: profile }
  console.log('updateC: ', data.profile.startDate)
  const timeStamp = Math.floor(Date.now() / 1000)
  const imageFile = profile.photoData
  const storageRef = storage.ref()

  if (profile.oldPhotoData === profile.photoData) {
    console.log('step 1')
    delete data.profile.oldPhotoData
    return editMerchantProfile(data)
      .then(function(result) {
        console.log(result.data.status)
        return result.data.status
      })
      .catch(function(error) {
        console.log(error)
      })
  } else {
    delete data.profile.photoData
    delete data.profile.oldPhotoData
    var uploadTask = storageRef
      .child('profiles/' + timeStamp + '-' + 'campaign')
      .put(imageFile)
    // Listen for state changes, errors, and completion of the upload.
    return uploadTask
      .then(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        }
      )
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break
          case 'storage/canceled':
            // User canceled the upload
            break

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      })
      .then(() => {
        console.log('Step 2')
        const storageRef = uploadTask.snapshot.ref.fullPath
        // Upload completed successfully, now we can get the download URL
        return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          data.profile.photoData = downloadURL
          data.profile.photoRef = storageRef

          return editMerchantProfile(data)
            .then(function(result) {
              console.log(result.data.status)
              return result.data.status
            })
            .catch(function(error) {
              console.log(error)
            })
        })
      })
  }
}

// Get all receipts in database
export const fetchAllCurrentReceipts = async (target) => {
  var fetchAllCurrentReceipts = fireFunctions.httpsCallable(
    'fetchAllCurrentReceipts'
  )

  try {
    const result = await fetchAllCurrentReceipts(target)
    return result
  } catch (error) {
    return console.log('Could not read all the receipts: ', error)
  }
}

export const activateOffer = async (offer) => {
  const activateOffer = fireFunctions.httpsCallable('activateOffer')

  await activateOffer(offer)
}

export const createOffer = async (offer) => {
  const createOffer = fireFunctions.httpsCallable('createOffer')

  await createOffer(offer)
}

export const subscribeToTargetList = async (subscription) => {
  const subscribeToTargetList = fireFunctions.httpsCallable('subscribeToTargetList')

  await subscribeToTargetList(subscription)
}

// Utils that we need
export {
  auth,
  db,
  usersCollection,
  storeTargetCollection,
  usersRole,
  storesCollection,
}
